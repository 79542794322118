import gql from 'graphql-tag'
import candidateFragment from './CandidateFragment'

export const UPDATED_CANDIDATE = gql `
subscription updateCandidate{
  updateCandidate{
    ...${candidateFragment}
  }
}
`
