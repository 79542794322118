<template>
  <div>
    <!-- {{ pageInterview }} -->
        <v-row class="">
          <div v-if="interview_id">
            <v-btn icon color="primary" @click="close(); pageInterview=''">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
        </v-row>
        <v-row class="">
        <div>
          <v-autocomplete
            v-if="interview_id"
            :items="selectInterview"
            :label="$t('scheduled_interviews')"
            item-text="text"
            item-value="key"
            dense
            solo
            style="width: 300px"
            clearable
            v-model="pageInterview"
            @change="selectInterviewPage()"
          ></v-autocomplete>
        </div>
      </v-row>
      <v-row class="mr-3">
    <v-simple-table dense v-if="!interview_id">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("vacancy_title") }}
            </th>
            <th>
              {{ $t("interviewers") }}
            </th>
            <th>
              {{ $t("observers") }}
            </th>
            <th>
              {{ $t("date") }}
            </th>
            <th>
              {{ $t("status") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in interviews" :key="item.id" style="white-space: nowrap" @click="goToInterview(item)">
            <td>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                 <span v-on="on" >{{ item.vacancy.title.length >15?item.vacancy.title.substring(0,15)+"...":item.vacancy.title  }}</span> 
                </template>
                <div
                  style="max-width: 200px;"
                  v-html="item.vacancy.title"
                ></div>
              </v-tooltip>
            </td>
            <td><avatar-group :items="avatarGroups(recrutersAndObservers(item).recruters)" /></td>
            <td>
              <avatar-group :items="avatarGroups(recrutersAndObservers(item).observers)" />
            </td>
            <td>{{ formatDate(item) }}</td>
            <td>
              <v-btn tile x-small dense :color="`${status(item).color} white--text`">
                {{ status(item).text }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    
    <interviewList  :interview_id="interview_id" v-if="interview_id && !pageInterview || pageInterview === 'scheduled_interviews'" @close="close" :candidate_id="candidate_id"/>
    <interviewListFinished  :interview_id="interview_id" v-if="interview_id && pageInterview === 'concluded_canceled_interviews'" @close="close" :candidate_id="candidate_id"/>
    <interviewListApproved  :interview_id="interview_id" v-if="interview_id && pageInterview === 'approved_candidates'" @close="close" :candidate_id="candidate_id" :vacancyId="vacancyId"/>
  </v-row>
  </div>
</template>

<script>
import { format } from "date-fns";
import AvatarGroup from "../../../../interview/components/recruter/AvatarGroupTable.vue";
import interviewList from "./schedule_ongoing/interviewList.vue";
import interviewListFinished from "./finished/interviewList.vue";
import interviewListApproved from "./approved/interviewList.vue";

export default {
  name: "InterviewTable",

  props: ["interviews","candidate_id","vacancyId"],

  components: {
    AvatarGroup,
    interviewList,
    interviewListFinished,
    interviewListApproved
  },

  data: () => ({
    interview_id: "",
    pageInterview:"scheduled_interviews"
  }),
  mounted(){
    this.pageInterview = "scheduled_interviews"
  },
  computed: {
    selectInterview() {
        return [
        { key: "scheduled_interviews", text: this.$t("scheduled_interviews") },
          {
            key: "concluded_canceled_interviews",
            text: this.$t("concluded_canceled_interviews"),
          },
          { key: "approved_candidates", text: this.$t("approved_candidates") },
         
        ];
      },
  },

  methods: {
    close(){
      this.interview_id = ""
    },
    selectInterviewPage() {
        console.log(this.pageInterview);
        if (this.pageInterview === "concluded_canceled_interviews") {
          //this.go();
        }
        if (this.pageInterview === "approved_candidates") {
        //   this.$router.push({
        //     name: "approved.interviews",
        //     params: {
        //       id: this.$route.params.id,
        //     },
        //   });
        }
    },
    goToInterview(interview) {
      this.interview_id = interview.id
      // this.$router.push({
      //   name: 'scheduled.interviews',
      //   params: { id: interview.id },
      // });
      // this.$router.push({
      //   name: "interviews.preview",
      //   params: { id: interview.id },
      // });
    },

    totalCandidates(interview) {
      return interview.candidates.length;
    },

    avatarGroups(recruters) {
      return recruters.map((mapRecruter) => ({
        id: mapRecruter.user.id,
        name: mapRecruter.user.name,
        photo: mapRecruter.user.photo,
      }));
    },

    recrutersAndObservers(interview) {
      const TOTAL_CANDIDATES = interview.candidates.length;
      const CANDIDATES = interview.candidates;
      let candidateIndex = 0;
      let recruters = [];
      let observers = [];

      for (
        candidateIndex;
        candidateIndex < TOTAL_CANDIDATES;
        candidateIndex++
      ) {
        const STAGES = CANDIDATES[candidateIndex].stages;
        const findedStage = STAGES.find(
          (findStage) =>
            findStage.name === CANDIDATES[candidateIndex].currentStage
        );
        recruters = [...recruters, ...findedStage.recruters];

        if (findedStage.observers) {
          observers = [...observers, ...findedStage.observers];
        }
      }

      recruters = recruters.filter((findRecruter, index) => {
        const recruterIds = recruters.map((mapRecruter) => mapRecruter.user.id);

        return recruterIds.indexOf(findRecruter.user.id) === index;
      });
      observers = observers.filter((findObserver, index) => {
        const observerIds = observers.map((mapObserver) => mapObserver.user.id);

        return observerIds.indexOf(findObserver.user.id) === index;
      });

      return { recruters, observers };
    },

    formatDate(interview) {
      const FIRST_ELEMENT = 0;
      const currentLang = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "pt";
      const DATE_OF_FIRST_INTERVIEW = new Date(
        interview.candidates[FIRST_ELEMENT].stages[FIRST_ELEMENT].date
      );
      let dateAndTime;

      switch (currentLang) {
        case "en":
          dateAndTime = format(DATE_OF_FIRST_INTERVIEW, "yyyy/MM/dd");
          break;

        default:
          dateAndTime = format(DATE_OF_FIRST_INTERVIEW, "dd/MM/yyyy");
          break;
      }

      return dateAndTime;
    },

    status(interview) {
      let states = [];
      const CANDIDATES = interview.candidates;
      CANDIDATES.forEach((mapCandidate) => {
        if (mapCandidate.status !== "pending") {
          states.push(mapCandidate.status);
        } else {
          mapCandidate.stages.forEach((mapStage) =>
            states.push(mapStage.state)
          );
        }
      });

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        running = 0,
        canceled = 0,
        ongoing = 0,
        completed = 0;
      const PENDING_STATUS = { text: this.$t("pending"), color: "cyan" };
      const ONGOING_STATUS = { text: this.$t("ongoing"), color: "warning" };
      const CANCELED_STATUS = { text: this.$t("canceled"), color: "error" };
      const FINISHED_STATUS = { text: this.$t("completed"), color: "success" };

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          case "rejected":
          case "approved":
            completed += 1;
            break;

          case "canceled":
            canceled += 1;
            break;

          case "ongoing":
          case "running":
          case "pause":
          case "finished":
            ongoing += 1;
            break;

          default:
            running += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return PENDING_STATUS;
      } else if (TOTAL_STATUS === ongoing || ongoing >= 1 || running >= 1) {
        return ONGOING_STATUS;
      } else if (TOTAL_STATUS === canceled) {
        return CANCELED_STATUS;
      } else if (
        TOTAL_STATUS === completed ||
        completed + canceled === TOTAL_STATUS
      ) {
        return FINISHED_STATUS;
      }

      return PENDING_STATUS;
    },
  },
};
</script>