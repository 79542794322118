import { render, staticRenderFns } from "./ExpansionPanel.vue?vue&type=template&id=6878f5b6&scoped=true"
import script from "./ExpansionPanel.vue?vue&type=script&lang=js"
export * from "./ExpansionPanel.vue?vue&type=script&lang=js"
import style0 from "./ExpansionPanel.vue?vue&type=style&index=0&id=6878f5b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6878f5b6",
  null
  
)

export default component.exports